import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { utc } from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, EMPTY, lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UsersApiService } from '@base/services/api/users/users-api.service';
import { environment } from '@env/environment';
import { attributesToObject } from '@misc/helpers/attributes-to-object.function';
import { CognitoAttributeName, User } from '@models/classes/user.model';
import { StorageKey } from '@models/enums/storage-key.enum';
import { Theme } from '@models/enums/theme.enum';
import { UserRole } from '@models/enums/user-role.enum';
import { IAuthenticationResult } from '@models/interfaces/authentication-result.interface';
import { ICognitoResult } from '@models/interfaces/cognito-result.interface';
import { LoaderService } from '@services/loader/loader.service';
import { StorageService } from '@services/storage/storage.service';
import { ThemeService } from '@services/theme/theme.service';
import { AppRoutingPaths } from 'src/app/app-routing.paths';
import { CognitoService } from '../cognito/cognito.service';
import { StonlyService } from '../stonly/stonly.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly me$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private readonly _ROLE$: BehaviorSubject<UserRole> = new BehaviorSubject<UserRole>(this._storageService.get(StorageKey.role));

  readonly role$ = this._ROLE$.asObservable();

  readonly isAuthorized$ = this.me$.pipe(
    map(user => {
      return !!user;
    })
  );

  constructor(
    private readonly _logger: NGXLogger,
    private _cognito: CognitoService,
    private _router: Router,
    private _storageService: StorageService,
    private _loaderService: LoaderService,
    private _themeService: ThemeService,
    private _usersApiService: UsersApiService,
    private _stonlyService: StonlyService,
    private _http: HttpClient
  ) {}

  get myRole(): UserRole {
    return this._ROLE$.value;
  }

  get mySelectedRole(): UserRole {
    return this._storageService.get(StorageKey.selectedRole) || this.myRole;
  }

  get me(): User {
    return this.me$.value;
  }

  async isAuthenticated(): Promise<boolean> {
    const isAuthenticated = await this._cognito.isAuthenticated();
    return Boolean(isAuthenticated && this.myRole);
  }

  async logIn(email: string, password: string, rememberMe: boolean): Promise<IAuthenticationResult> {
    this._loaderService.on();
    const authenticationResult: IAuthenticationResult = await this._cognito.logIn(email, password, rememberMe);
    if (authenticationResult.success) {
      if (rememberMe) {
        this._storageService.shouldUseLocalStorage = true;
      }
      await this.getMe(true);
      this._themeService.setTheme(Theme.light);
    }
    this._loaderService.off();
    return authenticationResult;
  }

  logout(): void {
    this._cognito.logOut();
    this.clearTokens();
    this._themeService.setTheme(Theme.light);
    this._router.navigate([AppRoutingPaths.AUTH, AppRoutingPaths.AUTH_COGNITO_LOGIN], { replaceUrl: true });
    return;
  }

  clearTokens(): void {
    this._storageService.clear();
    this._ROLE$.next(null);
    this.me$.next(null);
  }

  setRole(role: UserRole): UserRole {
    this._ROLE$.next(role);

    const beforeRole = this._storageService.get(StorageKey.role);
    if (!!beforeRole && beforeRole !== role) {
      this.routeToHome();
    }

    this._storageService.set(StorageKey.role, role);

    return role;
  }

  async getMe(isLogin: boolean = false): Promise<User | undefined> {
    if ((await this.isAuthenticated()) || isLogin) {
      try {
        const userAttributes: CognitoUserAttribute[] = await this._cognito.getUserAttributes();
        const attributes = attributesToObject(userAttributes);
        const user: User = await lastValueFrom(this._usersApiService.getItem(attributes.sub));

        this.setRole(user.role);
        this.me$.next(user);

        if (user?.id) {
          await this._cognito.updateAttributes([
            new CognitoUserAttribute({
              Name: CognitoAttributeName.LAST_SEEN,
              Value: `${utc(new Date()).format('yyyy-MM-DD HH:mm:ss')} UTC`
            })
          ]);
        }
        return user;
      } catch (e) {
        this._logger.error('getMe() failed', e);
        this.logout();
      }
    }

    return null;
  }

  async isAdmin(): Promise<boolean> {
    return await this._cognito.isAdmin();
  }

  async recoveryInit(email: string): Promise<ICognitoResult> {
    this._loaderService.on();
    const recoveryInitResponse = await this._cognito.recoveryInit(email);
    this._loaderService.off();
    return recoveryInitResponse;
  }

  async recoveryReset(email: string, password: string, code: string): Promise<ICognitoResult> {
    this._loaderService.on();
    const recoveryResetResponse = await this._cognito.recoveryReset(email, password, code);
    this._loaderService.off();
    return recoveryResetResponse;
  }

  setLoginPassword(email: string, password: string, session: any): Promise<any> {
    return this._cognito.handleNewPassword(email, password, session);
  }

  routeToLogin(): void {
    this._router.navigateByUrl(`${AppRoutingPaths.AUTH}/${AppRoutingPaths.AUTH_COGNITO_LOGIN}`);
    return;
  }

  routeToHome(): void {
    this._router.navigateByUrl(this.getHomeRoute());
  }

  setPicture(src: string): void {
    this.me$.next({ ...this.me, picture: src });
  }

  // Prepare backend usage
  resetStatus(): Observable<object> {
    return this._http.get(`${environment.API_URL}/status`, {}).pipe(catchError(() => EMPTY));
  }

  getHomeRoute(): string {
    const selectedRole: UserRole = this.mySelectedRole;
    if (selectedRole === UserRole.admin) return AppRoutingPaths.ADMIN_DASHBOARD;
    if (selectedRole === UserRole.coach) return AppRoutingPaths.COACH_DASHBOARD;
    if (selectedRole === UserRole.user) return AppRoutingPaths.CLIENT_DASHBOARD;
    return `${AppRoutingPaths.AUTH}/${AppRoutingPaths.AUTH_COGNITO_LOGIN}`;
  }
}
