import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import createHttpError from 'http-errors';
import { ToastrService } from 'ngx-toastr';

import { camelToSnakeCase } from '@misc/helpers/camel-to-snake-case.function';
import { CognitoErrorType } from '@models/enums/cognito-error-type';
import { UserRole } from '@models/enums/user-role.enum';
import { IAuthenticationResult } from '@models/interfaces/authentication-result.interface';
import { ICognitoResult } from '@models/interfaces/cognito-result.interface';
import { AppRoutingPaths } from 'src/app/app-routing.paths';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  static userSession: any;

  constructor(
    private _router: Router,
    private _translate: TranslateService,
    private _notification: ToastrService
  ) {}

  async getAccessToken(): Promise<CognitoAccessToken> {
    try {
      return (await this.getSession(this._getCurrentUser())).getAccessToken();
    } catch (e) {
      return null;
    }
  }

  async isAuthenticated(): Promise<boolean> {
    let isAuthenticated = false;
    const currentUser = this._getCurrentUser();
    if (currentUser) {
      const session = await this.getSession(currentUser);
      isAuthenticated = session.isValid() && !!this._getGroups(session).length;
    }
    return isAuthenticated;
  }

  async isAdmin(session?: CognitoUserSession): Promise<boolean> {
    let isAdmin = false;
    if (!session) {
      const user = this._getCurrentUser();
      session = await this.getSession(user);
    }
    if (session?.isValid?.()) {
      isAdmin = this._getGroups(session).includes(UserRole.admin);
    }
    return isAdmin;
  }

  async signUp(email: string, password: string, attributeList: CognitoUserAttribute[]): Promise<void> {
    this._getUserPool().signUp(email, password, attributeList, [], err => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  }

  async logIn(email: string, password: string, rememberMe: boolean): Promise<IAuthenticationResult> {
    const user = new CognitoUser({ Username: email, Pool: this._getUserPool(), Storage: rememberMe ? localStorage : sessionStorage });
    const authenticationResult = await this._authenticateUser(user, email, password);
    if (authenticationResult.success && authenticationResult.session) {
      authenticationResult.admin = await this.isAdmin(authenticationResult.session);
    }
    return authenticationResult;
  }

  async recoveryInit(email: string): Promise<ICognitoResult | any> {
    const user = new CognitoUser({ Username: email, Pool: this._getUserPool() });
    return new Promise(resolve => {
      user.forgotPassword({
        onSuccess: result => {
          resolve({ success: true, result: result });
        },
        onFailure: err => {
          this._onError(err);
          resolve({ success: false, message: err.message });
        }
      });
    }).catch((err: Error) => this._onError(err));
  }

  async updateAttributes(attributes: CognitoUserAttribute[]): Promise<any> {
    const user = this._getCurrentUser();
    if (user) {
      await this.getSession(user);
      return new Promise((resolve, reject) => {
        user.updateAttributes(attributes, (err, data) => {
          err !== null ? reject(err) : resolve(data);
        });
      }).catch((err: Error) => this._onError(err));
    }
    return null;
  }

  async recoveryReset(email: string, password: string, code: string): Promise<ICognitoResult | any> {
    const user = new CognitoUser({ Username: email, Pool: this._getUserPool() });
    return new Promise(resolve => {
      user.confirmPassword(code, password, {
        onSuccess: result => {
          resolve({ success: true, result: result });
        },
        onFailure: err => {
          this._onError(err);
          resolve({ success: false, message: err.message });
        }
      });
    }).catch((err: Error) => this._onError(err));
  }

  logOut(): void {
    const currentUser = this._getCurrentUser();
    currentUser?.signOut();
    this._router.navigate([AppRoutingPaths.HOME]);
    return null;
  }

  async getUserAttributes(): Promise<CognitoUserAttribute[] | any> {
    const user = this._getCurrentUser();
    if (user) {
      await this.getSession(user);
      return new Promise((resolve, reject) => {
        user.getUserAttributes((err, data) => {
          err !== null ? reject(err) : resolve(data);
        });
      }).catch((err: Error) => {
        if (err.name === 'PasswordResetRequiredException') {
          this.logOut();
          this._router.navigate([AppRoutingPaths.AUTH, AppRoutingPaths.AUTH_COGNITO_LOGIN]);
        }
        this._onError(err);
      });
    }
    return null;
  }

  async getUserGroups(): Promise<string[]> {
    try {
      const user = this._getCurrentUser();
      const session = await this.getSession(user);
      return this._getGroups(session);
    } catch (e) {
      throw createHttpError(500, 'Failed to getUserGroups');
    }
  }

  async getSession(currentUser: CognitoUser): Promise<any> {
    return new Promise((resolve, reject) => {
      currentUser?.getSession((err: null, data: CognitoUserSession) => {
        if (err !== null) {
          reject(err);
          this._onError(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  handleNewPassword(email: string, password: string, session: any): Promise<any> {
    return new Promise(resolve => {
      CognitoService.userSession.completeNewPasswordChallenge(password, session, {
        onSuccess: (result: any) => {
          this._notification.success(this._translate.instant('MESSAGE.PASSWORD_CHANGED_SUCCESS'));
          resolve({ success: true, session: result });
        },
        onFailure: (err: any) => {
          this._onError(
            CognitoErrorType.NotAuthorizedException ? { name: CognitoErrorType.EmailVerificationException, message: err.message } : err
          );
          resolve({ success: false, session: null, admin: false, message: err.message, type: err?.name });
        }
      });
    });
  }

  async changePassword(oldPassword: string, password: string): Promise<any> {
    const user = this._getCurrentUser();
    if (user) {
      await this.getSession(user);
      return new Promise((resolve, reject) => {
        user.changePassword(oldPassword, password, (err, data) => {
          err !== null ? reject(err) : resolve(data);
        });
      }).catch((err: Error) => this._onError(err));
    }
    return null;
  }

  async getVerificationEmailCode(): Promise<any> {
    const user = this._getCurrentUser();
    if (user) {
      await this.getSession(user);
      return new Promise(resolve => {
        user.getAttributeVerificationCode('email', {
          onSuccess: result => {
            resolve({ success: true, session: result });
          },
          onFailure: err => {
            this._onError(err);
            resolve({ success: false, session: null, message: err.message, type: err?.name });
          }
        });
      }).catch((err: Error) => this._onError(err));
    }
    return null;
  }

  async verificationEmailCode(code: string): Promise<any> {
    const user = this._getCurrentUser();
    if (user) {
      await this.getSession(user);
      return new Promise(resolve => {
        user.verifyAttribute('email', code, {
          onSuccess: result => {
            resolve({ success: true, session: result });
          },
          onFailure: err => {
            this._onError(err);
            resolve({ success: false, session: null, message: err.message, type: err?.name });
          }
        });
      }).catch((err: Error) => this._onError(err));
    }
    return null;
  }

  routeToLogin(): void {
    this._router.navigateByUrl(`${AppRoutingPaths.AUTH}/${AppRoutingPaths.AUTH_COGNITO_LOGIN}`);
    return null;
  }

  routeToHome(): void {
    this._router.navigateByUrl(AppRoutingPaths.HOME);
    return null;
  }

  private _getCurrentUser(): CognitoUser | null {
    // INFO: depending on rememberMe different storages might be used for jwts
    let user = this._getUserPool().getCurrentUser();
    if (!user) {
      user = this._getUserPool(sessionStorage).getCurrentUser();
    }
    return user;
  }

  private _getUserPool(storage?: Storage): CognitoUserPool {
    const userPool: ICognitoUserPoolData = {
      UserPoolId: environment.COGNITO_USER_POOL_ID,
      ClientId: environment.COGNITO_USER_POOL_CLIENT_ID,
      Storage: storage ?? localStorage
    };
    return new CognitoUserPool(userPool);
  }

  private _onError(error: Error): void {
    const key: string = `BACKEND_ERRORS.${camelToSnakeCase(error.name)}`;
    const translate: string = this._translate.instant(key);
    const notificationMessage: string = translate !== key ? translate : error.message;

    this._notification.error(notificationMessage);
  }

  private async _authenticateUser(user: CognitoUser, email: string, password: string): Promise<IAuthenticationResult | any> {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    return new Promise(resolve => {
      user.authenticateUser(authDetails, {
        onSuccess: result => {
          resolve({ success: true, session: result });
        },
        onFailure: err => {
          resolve({ success: false, session: null, admin: false, message: err.message, type: err?.name });
        },
        newPasswordRequired: function f(userAttributes: any) {
          delete userAttributes.phone_number_verified;
          delete userAttributes.email_verified;
          delete userAttributes.email;

          CognitoService.userSession = user;
          resolve({ success: false, session: userAttributes, admin: false, type: CognitoErrorType.PasswordSetNewPasswordException });
        }
      });
    });
  }

  private _getGroups(session: CognitoUserSession): string[] {
    if (session.isValid()) {
      return session.getAccessToken().payload['cognito:groups'];
    }
    return [];
  }
}
