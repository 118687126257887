import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ClassConstructor } from 'class-transformer';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBaseAbstractService } from '@base/services/api/api-base.abstract.service';
import { attributesToModel } from '@misc/helpers/attributes-to-object.function';
import { User } from '@models/classes/user.model';
import { IServicesConfig } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService extends ApiBaseAbstractService<User> {
  protected readonly _URL_PATH: string = '/users';
  protected readonly _MODEL: ClassConstructor<User> = User;

  override getItem(id: string, services?: IServicesConfig): Observable<User> {
    return zip(this._http.get(`${this.url}/${id}`, {}, services), this.getAvatar(id)).pipe(
      map(([responseUser, picture]: [any, string]) => attributesToModel(responseUser.user, User, { picture }))
    );
  }

  // INFO: [GET] /users/:id/avatar
  getAvatar(id: string, services?: IServicesConfig): Observable<string> {
    return this._http
      .get(
        `${this.url}/${id}/avatar`,
        {
          headers: {
            'Content-Type': 'text/html'
          }
        },
        services
      )
      .pipe(map((res: any) => res?.avatar));
  }

  // INFO: [PUT] /users/:id/avatar
  createAvatar(id: string, params?: Params, services?: IServicesConfig): Observable<any> {
    return this._http.put(`${this.url}/${id}/avatar`, params, {}, services);
  }

  // INFO: [PATCH] /users/:id/avatar
  setAvatar(id: string, params?: Params, services?: IServicesConfig): Observable<any> {
    return this._http.patch(`${this.url}/${id}/avatar`, params, {}, services);
  }

  // INFO: [DELETE] /users/:id/avatar
  deleteAvatar(id: string, params?: Params, services?: IServicesConfig): Observable<any> {
    return this._http.delete(`${this.url}/${id}/avatar`, params, services);
  }
}
