import { HttpErrorResponse } from '@angular/common/http';

export interface IErrorDescription {
  message: string;
  key: string;
}

export class HttpServiceError extends HttpErrorResponse {
  get descriptions(): IErrorDescription[] {
    return this._isFormError ? this._getFormFieldsErrorDescriptions(this) : [this._getSimpleErrorMessage()];
  }

  private get _isFormError(): boolean {
    return Boolean(this.error?.fields || this.error?.errors || this.error?.violations);
  }

  constructor(e: HttpErrorResponse) {
    super(e);
  }

  private _getSimpleErrorMessage(): IErrorDescription {
    let message: string = `${this.status}: ${this.statusText}`;
    let key: string;

    if (this.error) {
      if (this.error.error_description) {
        message = this.error.error_description;
      }

      if (this.error.error_message) {
        message = this.error.error_message;
      }

      if (this.error.message) {
        message = this.error.message;
      }

      if (this.error.detail) {
        message = this.error.detail;
      }

      if (this.error['hydra:description']) {
        message = this.error['hydra:description'];
      }

      key = this.error.error;
    }

    return { key: key ?? message.replace(/\s/gm, '_').replace(/\W/gm, ''), message };
  }

  private _getFormFieldsErrorDescriptions(error: HttpErrorResponse): IErrorDescription[] {
    const { violations }: { violations: { code: string; message: string; payload: { code: string } }[] } = error.error;
    const result: IErrorDescription[] = [];

    if (violations) {
      violations.forEach((obtainedError: { code: string; message: string; payload: { code: string } }): void => {
        result.push({ key: obtainedError.payload?.code ?? obtainedError?.code, message: obtainedError.message });
      });
    }

    return result;
  }
}
