import { Injectable } from '@angular/core';
import { StorageKey } from '@models/enums/storage-key.enum';
import { Theme } from '@models/enums/theme.enum';
import { StorageService } from '@services/storage/storage.service';
import { BehaviorSubject, skip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(this._storageService.get<Theme>(StorageKey.theme) || Theme.light);

  get theme(): Theme {
    return this.theme$.value;
  }

  constructor(private _storageService: StorageService) {
    this._updateData(this.theme);

    this.theme$.pipe(skip(1)).subscribe((changeTheme: Theme) => {
      this._updateData(changeTheme);
    });
  }

  setTheme(themeValue: Theme): void {
    this.theme$.next(themeValue);
  }

  toggle(): void {
    this.setTheme(this.theme === Theme.light ? Theme.dark : Theme.light);
  }

  private _updateData(value: Theme): void {
    const root = document.querySelector('html');
    this._storageService.set(StorageKey.theme, value);
    root.className = value;
  }
}
