import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, skip, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { User, UserRoleFlag } from '@models/classes/user.model';
import { AppIcon } from '@models/enums/app.icons';
import { StorageKey } from '@models/enums/storage-key.enum';
import { UserRole } from '@models/enums/user-role.enum';
import { AuthService } from '@services/auth/auth.service';
import { StorageService } from '@services/storage/storage.service';

export interface IRoleSwitchItem {
  icon: AppIcon;
  label: string;
  value: UserRole;
}

const roleItems: Record<keyof UserRoleFlag, IRoleSwitchItem> = {
  isUser: {
    icon: AppIcon.USER_CIRCLE,
    label: 'User',
    value: UserRole.user
  },
  isCoach: {
    icon: AppIcon.PEOPLE_CIRCLE,
    label: 'Coach',
    value: UserRole.coach
  },
  isAdmin: {
    icon: AppIcon.COG_CIRCLE,
    label: 'Admin',
    value: UserRole.admin
  }
};

@Injectable({
  providedIn: 'root'
})
export class RoleSwitcherService {
  storage: StorageService = inject(StorageService);
  auth: AuthService = inject(AuthService);
  readonly ROLE_ITEMS: IRoleSwitchItem[] = [];
  readonly SELECTED_ITEM$: BehaviorSubject<IRoleSwitchItem> = new BehaviorSubject<IRoleSwitchItem>(this._getSelectedRoleItem());
  private _router: Router = inject(Router);

  readonly selectedRole$: Observable<UserRole> = combineLatest([this.SELECTED_ITEM$, this.auth.role$]).pipe(
    map(([roleSwitchItem, role]: [IRoleSwitchItem, UserRole]) => {
      return roleSwitchItem?.value || role;
    })
  );

  readonly isAdmin$: Observable<boolean> = this.selectedRole$.pipe(
    map((selectedRole: UserRole) => {
      return selectedRole === UserRole.admin;
    })
  );

  readonly isCoach$: Observable<boolean> = this.selectedRole$.pipe(
    map((selectedRole: UserRole) => {
      return selectedRole === UserRole.coach;
    })
  );

  readonly isUser$: Observable<boolean> = this.selectedRole$.pipe(
    map((selectedRole: UserRole) => {
      return selectedRole === UserRole.user;
    })
  );

  readonly isAdmin = toSignal(this.isAdmin$, { initialValue: false });
  readonly isCoach = toSignal(this.isCoach$, { initialValue: false });
  readonly isUser = toSignal(this.isUser$, { initialValue: true });

  constructor() {
    this.auth.me$
      .pipe(
        skip(1),
        tap((user: User): void => !user && this._clearRoleItems()),
        filter(Boolean)
      )
      .subscribe((): void => {
        this.setSelectedItem(this._getSelectedRoleItem());
        this._setRoleOptions();
      });
  }

  get selectedItem(): IRoleSwitchItem {
    return this.SELECTED_ITEM$.value;
  }

  get selectedRole(): UserRole {
    return this.selectedItem?.value || this.auth.myRole;
  }

  setSelectedItem(item: IRoleSwitchItem): void {
    this.storage.set(StorageKey.selectedRole, item.value || this.auth.myRole);
    if (this.selectedRole !== item.value) this._router.navigate([this.auth.getHomeRoute()]);
    this.SELECTED_ITEM$.next(item);
    this._setRoleOptions();
  }

  private _clearRoleItems(): void {
    this.SELECTED_ITEM$.next(null);
    this.ROLE_ITEMS.length = 0;
    this.storage.remove(StorageKey.selectedRole);
  }

  private _getSelectedRoleItem(): IRoleSwitchItem {
    const storedRole: UserRole = this.storage.get<UserRole>(StorageKey.selectedRole);
    const role: UserRole = Object.values(UserRole).includes(storedRole) ? storedRole : this.auth.myRole;
    return Object.values(roleItems).find((item: IRoleSwitchItem) => item.value === role);
  }

  private _setRoleOptions(): void {
    this.ROLE_ITEMS.length = 0;

    Object.entries<IRoleSwitchItem>(roleItems).forEach(([flag, item]: [string, IRoleSwitchItem]): void => {
      if (this.auth.me[flag as keyof UserRoleFlag] && item.value !== this.selectedRole) {
        this.ROLE_ITEMS.push(item);
      }
    });
  }
}
