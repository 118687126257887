import { UserRole } from '@models/enums/user-role.enum';
import {
  dashboardPermissionMatrix,
  dashboardPermissionType
} from '@directives/has-permissions/permission-matrix/_dashboard.permission-matrix';
import {
  dashboardAdminPermissionMatrix,
  dashboardAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_dashboard-admin.permission-matrix';
import {
  modulesAdminPermissionMatrix,
  modulesAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_modules-admin.permission-matrix';
import {
  usersAdminPermissionMatrix,
  usersAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_users-admin.permission-matrix';
import { profilePermissionMatrix, profilePermissionType } from '@directives/has-permissions/permission-matrix/_profile.permission-matrix';
import {
  overviewPermissionMatrix,
  overviewPermissionType
} from '@directives/has-permissions/permission-matrix/_overview.permission-matrix';
import {
  organizationsAdminPermissionMatrix,
  organizationsAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_organizations-admin.permission-matrix';
import {
  notificationAdminPermissionMatrix,
  notificationAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_notification-admin.permission-matrix';
import { helpPermissionMatrix, helpPermissionType } from '@directives/has-permissions/permission-matrix/_help.permission-matrix';
import { toolsPermissionMatrix, toolsPermissionType } from '@directives/has-permissions/permission-matrix/_tools.permission-matrix';
import {
  securityPermissionMatrix,
  securityPermissionType
} from '@directives/has-permissions/permission-matrix/_security.permission-matrix';
import {
  sitemapAdminPermissionMatrix,
  sitemapAdminPermissionType
} from '@directives/has-permissions/permission-matrix/_sitemap-admin.permission-matrix';
import {
  dashboardCoachPermissionMatrix,
  dashboardCoachPermissionType
} from '@directives/has-permissions/permission-matrix/_dashboard-coach.permission-matrix';
import {
  transformationPermissionMatrix,
  transformationPermissionType
} from '@directives/has-permissions/permission-matrix/_transformation.permission-matrix';

export type permissionType =
  | dashboardPermissionType
  | dashboardAdminPermissionType
  | dashboardCoachPermissionType
  | modulesAdminPermissionType
  | usersAdminPermissionType
  | overviewPermissionType
  | organizationsAdminPermissionType
  | notificationAdminPermissionType
  | helpPermissionType
  | toolsPermissionType
  | securityPermissionType
  | sitemapAdminPermissionType
  | profilePermissionType
  | transformationPermissionType;

export type CRUDPermissionType<T extends string> = `${T}_view` | `${T}_create` | `${T}_edit` | `${T}_delete`;
export type IPermissionMatrix<T extends string> = Record<T, UserRole[]>;

export const permissionMatrix: IPermissionMatrix<permissionType> = {
  ...dashboardPermissionMatrix,
  ...dashboardAdminPermissionMatrix,
  ...dashboardCoachPermissionMatrix,
  ...modulesAdminPermissionMatrix,
  ...usersAdminPermissionMatrix,
  ...overviewPermissionMatrix,
  ...profilePermissionMatrix,
  ...organizationsAdminPermissionMatrix,
  ...notificationAdminPermissionMatrix,
  ...helpPermissionMatrix,
  ...toolsPermissionMatrix,
  ...securityPermissionMatrix,
  ...sitemapAdminPermissionMatrix,
  ...transformationPermissionMatrix
};
