import { Injectable } from '@angular/core';
import { StorageKey } from '@models/enums/storage-key.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly _SHOULD_USE_LOCAL_STORAGE$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set shouldUseLocalStorage(shouldUseLocalStorage: boolean) {
    this._SHOULD_USE_LOCAL_STORAGE$.next(shouldUseLocalStorage);
  }

  get current(): Storage {
    const alreadyUsedStorage: Storage = [sessionStorage, localStorage].find((storage: Storage): string =>
      storage.getItem(StorageKey.tokens)
    );
    const newStorage: Storage = this._SHOULD_USE_LOCAL_STORAGE$.value ? localStorage : sessionStorage;

    return alreadyUsedStorage || newStorage;
  }

  get<T = string>(key: string): T | null {
    const storage: Storage = [sessionStorage, localStorage].find((storage: Storage): boolean => Boolean(storage.getItem(key)));
    return (storage?.getItem(key) as T) ? (storage?.getItem(key) as T) : null;
  }

  set(name: StorageKey, value: unknown): void {
    if (value == null) {
      this.current.setItem(name, '');
    } else if (typeof value === 'string') {
      this.current.setItem(name, value ?? '');
    } else {
      this.current.setItem(name, JSON.stringify(value));
    }
  }

  remove(key: string): void {
    [sessionStorage, localStorage].forEach((storage: Storage): void => {
      storage.removeItem(key);
    });
  }

  clear(): void {
    [sessionStorage, localStorage].forEach((storage: Storage): void => storage.clear());
  }
}
