import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpRequestOptions } from '@models/interfaces/http-request-options.interface';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '@services/cognito/cognito.service';
import { HttpServiceError, IErrorDescription } from '@services/http/http-service-error.class';
import { LoaderService } from '@services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, of, switchMap } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

export interface IServicesConfig {
  skipErrorNotification?: ((err: HttpServiceError) => boolean) | boolean;
  showSuccessNotification?: { text: string };
  skipLoaderStart?: boolean;
  skipLoaderEnd?: boolean;
}

interface WindowWithAuth extends Window {
  collider_pdf_auth: string;
}

declare let window: WindowWithAuth;

@Injectable({
  providedIn: 'root'
})
export class HttpService extends HttpClient {
  constructor(
    handler: HttpHandler,
    private _notification: ToastrService,
    private _loader: LoaderService,
    private _translate: TranslateService,
    protected readonly _cognito: CognitoService
  ) {
    super(handler);
  }

  getAuthHeader(): Observable<any> {
    // If the server visits the site for PDF generation, it will set the collider_pdf_auth variable in the window object.
    if (window.collider_pdf_auth) {
      return of({ Authorization: window.collider_pdf_auth });
    }
    return from(this._cognito.getAccessToken()).pipe(map(token => ({ Authorization: token.getJwtToken() })));
  }

  override get(url: string, options?: IHttpRequestOptions, services?: IServicesConfig | null): Observable<any> {
    return of(null).pipe(
      tap(() => this._startLoader(services)),
      switchMap(() => this.getAuthHeader()),
      switchMap(headers => {
        const httpOptions: IHttpRequestOptions = {
          headers: new HttpHeaders({
            Accept: 'application/ld+json',
            ...headers
          })
        };

        delete options?.headers;

        return super
          .get(url, { ...httpOptions, ...options } as IHttpRequestOptions)
          .pipe(
            tap(this._onSuccess.bind(this, services)),
            catchError(this._onError.bind(this, services)),
            finalize(this._onEveryCase.bind(this, services))
          );
      })
    );
  }

  override post(url: string, body: any | null, options?: IHttpRequestOptions, services?: IServicesConfig | null): Observable<any> {
    return of(null).pipe(
      tap(() => this._startLoader(services)),
      switchMap(() => this.getAuthHeader()),
      switchMap(headers =>
        super
          .post(url, body, { ...options, headers: { ...headers, ...(options?.headers || {}) } })
          .pipe(
            tap(this._onSuccess.bind(this, services)),
            catchError(this._onError.bind(this, services)),
            finalize(this._onEveryCase.bind(this, services))
          )
      )
    );
  }

  override patch(url: string, body: any | null, options?: IHttpRequestOptions, services?: IServicesConfig | null): Observable<any> {
    return of(null).pipe(
      tap(() => this._startLoader(services)),
      switchMap(() => this.getAuthHeader()),
      switchMap(headers => {
        const httpOptions: { headers: HttpHeaders } = {
          headers: new HttpHeaders({
            'Content-Type': 'application/merge-patch+json',
            ...headers,
            ...(options?.headers || {})
          })
        };

        delete options?.headers;

        return super
          .patch(url, body, { ...httpOptions, ...options, headers: { ...headers, ...(options?.headers || {}) } })
          .pipe(
            tap(this._onSuccess.bind(this, services)),
            catchError(this._onError.bind(this, services)),
            finalize(this._onEveryCase.bind(this, services))
          );
      })
    );
  }

  override delete(url: string, options?: IHttpRequestOptions, services?: IServicesConfig | null): Observable<any> {
    return of(null).pipe(
      tap(() => this._startLoader(services)),
      switchMap(() => this.getAuthHeader()),
      switchMap(headers =>
        super
          .delete(url, { ...options, headers: { ...headers, ...(options?.headers || {}) } })
          .pipe(
            tap(this._onSuccess.bind(this, services)),
            catchError(this._onError.bind(this, services)),
            finalize(this._onEveryCase.bind(this, services))
          )
      )
    );
  }

  override put(url: string, body: any | null, options?: IHttpRequestOptions, services?: IServicesConfig | null): Observable<any> {
    return of(null).pipe(
      tap(() => this._startLoader(services)),
      switchMap(() => this.getAuthHeader()),
      switchMap(headers =>
        super
          .put(url, body, { ...options, headers: { ...headers, ...(options?.headers || {}) } })
          .pipe(
            tap(this._onSuccess.bind(this, services)),
            catchError(this._onError.bind(this, services)),
            finalize(this._onEveryCase.bind(this, services))
          )
      )
    );
  }

  private _onSuccess(config: IServicesConfig): void {
    if (config?.showSuccessNotification) {
      this._notification.success(config?.showSuccessNotification?.text ?? 'Request successfully sent!');
    }
  }

  private _onError(config: IServicesConfig, error: HttpErrorResponse): Observable<HttpServiceError> {
    const customError: HttpServiceError = new HttpServiceError(error);

    if (
      !config ||
      !(typeof config.skipErrorNotification === 'boolean' ? config.skipErrorNotification : config.skipErrorNotification?.(customError))
    ) {
      customError.descriptions.forEach(({ key, message }: IErrorDescription): void => {
        const notificationMessage: string = key ? this._translate.instant(`BACKEND_ERRORS.${key.toUpperCase()}`) : message;

        this._notification.error(notificationMessage);
      });
    }

    throw customError;
  }

  private _onEveryCase(config: IServicesConfig): void {
    this._endLoader(config);
  }

  private _startLoader(config: IServicesConfig): void {
    if (!config || (config && !config.skipLoaderStart)) {
      this._loader.on();
    }
  }

  private _endLoader(config: IServicesConfig): void {
    if (!config || (config && !config.skipLoaderEnd)) {
      this._loader.off();
    }
  }
}
