export enum StorageKey {
  tokens = 'tokens',
  role = 'role',
  selectedRole = 'selectedRole',
  theme = 'theme',
  jwt = 'jwt',
  user = 'user',
  activity = 'activity',
  skipIntro = 'skipIntro'
}
