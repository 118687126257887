import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@base/models/classes/user.model';
import { environment } from '@env/environment';

interface ICustomWindow extends Window {
  StonlyWidget: any;
  STONLY_WID: string;
}

@Injectable({
  providedIn: 'root'
})
export class StonlyService {
  scriptInstance: HTMLScriptElement;
  isScriptLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _document: Document = inject<Document>(DOCUMENT);
  private _platform: Platform = inject(Platform);
  private _scriptSrc: string = 'assets/js/stonly/stonly-widget.js';

  private get _window(): ICustomWindow {
    return this._document.defaultView as unknown as ICustomWindow;
  }

  identifyUser(curUserId: User['id']): void {
    if (this.isScriptLoaded$.value) {
      this._window.StonlyWidget('identify', curUserId);
    }
  }

  init(): void {
    if (!this._platform.isBrowser) return;
    if (this.scriptInstance) {
      this._window.StonlyWidget = null;
      this.scriptInstance.remove();
      this.isScriptLoaded$.next(false);
    }
    this._window.STONLY_WID = environment.STONLY_WID;
    this.scriptInstance = this._document.createElement('script');
    this.scriptInstance.type = 'text/javascript';
    this.scriptInstance.defer = true;
    this.scriptInstance.src = this._scriptSrc;
    this.scriptInstance.onload = (): void => this.isScriptLoaded$.next(true);
    this._document.body.appendChild(this.scriptInstance);
  }
}
