import { Params } from '@angular/router';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { ClassConstructor } from 'class-transformer';

import { convertToModel } from '@misc/helpers/model-conversion/convert-to-model.function';

export function attributesToObject(attributes: CognitoUserAttribute[]): Params {
  const params: Params = {};
  attributes.forEach((attr: CognitoUserAttribute) => (params[attr.Name] = attr.Value));

  return params;
}

export function attributesToModel<T>(item: Params, model: ClassConstructor<T>, extraParams: Params = {}): T {
  const userAttributes = attributesToObject(item.Attributes);
  delete item.Attributes;
  return convertToModel({ ...userAttributes, ...item, ...extraParams }, model);
}
