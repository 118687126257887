import { UserRole } from '@models/enums/user-role.enum';
import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer';
import { transformToDate } from '@misc/helpers/model-conversion/transform-helpers/transform-to-date.function';

export enum CognitoAttributeName {
  ID = 'sub',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  FIRST_NAME = 'given_name',
  LAST_NAME = 'family_name',
  PICTURE = 'picture',
  VERIFIED = 'email_verified',
  ADDRESS = 'address',
  COMPANY = 'custom:company',
  ADDRESS_ADDITIONAL = 'custom:address_addition',
  POST_CODE = 'custom:post_code',
  CITY = 'custom:city',
  LAST_SEEN = 'custom:last_seen'
}

export type UserRoleFlag = Pick<User, 'isUser' | 'isCoach' | 'isAdmin'>;

@Exclude()
export class User {
  @Expose({ name: CognitoAttributeName.ID })
  id: string;
  @Expose({ name: CognitoAttributeName.EMAIL })
  email: string;
  @Expose({ name: CognitoAttributeName.FIRST_NAME })
  firstName: string;
  @Expose({ name: CognitoAttributeName.LAST_NAME })
  lastName: string;
  @Expose({ name: CognitoAttributeName.PICTURE })
  picture: string;
  @Expose({ name: 'IsAdmin' })
  isAdmin: boolean;
  @Expose({ name: 'IsCoach' })
  isCoach: boolean;
  @Expose({ name: 'IsUser' })
  isUser: boolean;
  @Expose({ name: 'UserRole' })
  role: UserRole;
  @Expose({ name: CognitoAttributeName.VERIFIED })
  @Transform(({ value }: TransformFnParams): boolean => value === 'true')
  verified: boolean;
  @Expose({ name: 'Enabled' })
  enabled?: boolean;
  @Expose({ name: 'UserCreateDate' })
  @Transform(transformToDate)
  createAt?: Date;
  @Expose({ name: 'UserLastModifiedDate' })
  @Transform(transformToDate)
  updateAt?: Date;
  @Expose({ name: CognitoAttributeName.LAST_SEEN })
  @Transform(transformToDate)
  lastSeen?: Date;
  @Expose({ name: 'UserStatus' })
  userStatus?: string;
  @Expose()
  @Transform(
    ({ value, obj }: TransformFnParams): string =>
      value ?? `${obj[CognitoAttributeName.FIRST_NAME] ?? ''} ${obj[CognitoAttributeName.LAST_NAME] ?? ''}`.trim()
  )
  fullName: string;
  @Expose({ name: CognitoAttributeName.COMPANY })
  company?: string;
  @Expose({ name: CognitoAttributeName.PHONE_NUMBER })
  phoneNumber?: string;
  @Expose({ name: CognitoAttributeName.ADDRESS })
  address?: string;
  @Expose({ name: CognitoAttributeName.ADDRESS_ADDITIONAL })
  addressAddition?: string;
  @Expose({ name: CognitoAttributeName.POST_CODE })
  postCode?: string;
  @Expose({ name: CognitoAttributeName.CITY })
  city?: string;
}
