export enum AppIcon {
  RECOVERY_CHECK = 'recovery-check',
  LOG_OUT = 'log-out',
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  TOOLS = 'tools',
  SECURITY = 'security',
  HELP = 'help',
  USERS_DARKMODE = 'users-darkmode',
  ADMIN_DASHBOARD = 'admin-dashboard',
  ADMIN_MODULES = 'admin-modules',
  ADMIN_USERS = 'admin-users',
  ADMIN_NOTIFICATIONS = 'admin-notifications',
  ERROR_MSG = 'error-msg',

  ERROR_ICON = 'error-icon',
  QUESTION_ICON = 'question-icon',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DEACTIVATE = 'deactivate',
  RESET = 'reset',
  DELETE = 'delete',
  DROPDOWN = 'dropdown',
  TRASH = 'trash',
  UPLOAD = 'upload',
  CAMERA = 'camera',
  CHECK = 'check',
  CHECK_2 = 'check-2',
  CHECK_CIRCLE_2 = 'check-circle-2',
  NOTIFICATION_ACTIVITY = 'notification-activity',
  ORGANIZATION = 'organization',
  BULB = 'bulb',
  QUESTION = 'question',
  CENTER_BTN = 'center-btn',
  CROP_BTN = 'crop-btn',
  ROTATE_BTN = 'rotate-btn',
  UPLOAD_BTN = 'upload-btn',
  ZOOM_MAX_BTN = 'zoom-max-btn',
  ZOOM_MIN_BTN = 'zoom-min-btn',
  CHECK_CIRCLE = 'check-circle',
  CLOSE_CIRCLE = 'close-circle',
  PEOPLE_CIRCLE = 'people-circle',
  CIRCLE = 'circle',
  LIST = 'list',
  CLOSE = 'close',
  COG_CIRCLE = 'cog-circle',
  USER_CIRCLE = 'user-circle',
  SEND_MAIL = 'send-mail',
  PHONE = 'phone',
  CHEVRON = 'chevron',
  BOOK_CIRCLE = 'book-circle',
  BULLET_CHECK = 'bullet-check',
  BULLET_CHECK_ACTIVE = 'bullet-check-active',
  PASSED_CIRCLE = 'passed-circle',
  INFO = 'info',
  INFO_20 = 'info-20',
  BLOCK_SINGLE_CHOICE_CHECKBOX = 'block-single-choice-checkbox',
  QUERY = 'query',
  COPY = 'copy',
  COPY_ID = 'copy-id',
  LINK = 'link',
  TAG = 'tag',
  INFO_ICON = 'infoIcon',
  BULB_ICON = 'bulbIcon',
  DOC_ICON = 'docIcon',
  ARROW_RIGHT = 'expand-right',
  CODE = 'code',
  IMAGE = 'image',
  VIDEO = 'video',
  DRAGGABLE = 'draggable',
  EDIT = 'edit',
  SWAP = 'swap',
  NO = 'no',
  EYE = 'eye',
  ADD = 'add',
  DRAG_HINT = 'drag-hint',
  AXIS_ARROW = 'axis-arrow',
  WARNING = 'warning',
  SUCCESS = 'success',
  TEXT_TOP = 'align-top',
  TEXT_CENTER = 'align-center',
  TEXT_BOTTOM = 'align-bottom',
  PLUS = 'plus',

  // Block Type Icons
  BLOCK_TEXT = 'block-text',
  BLOCK_TEXT_COLUMNS = 'block-text-columns',
  BLOCK_IMAGE = 'block-image',
  BLOCK_TEXT_IMAGE = 'block-text-image',
  BLOCK_IMAGE_TEXT = 'block-image-text',
  BLOCK_VIDEO = 'block-video',
  BLOCK_EMBEDDED = 'block-embedded',
  BLOCK_SINGLE_CHOICE = 'block-single-choice',
  BLOCK_INPUT = 'block-input',
  BLOCK_SELECTION = 'block-selection',
  BLOCK_SLIDER = 'block-slider',
  BLOCK_QUESTION = 'block-question',
  BLOCK_ROLE_INPUT = 'block-role-input',
  BLOCK_INPUT_SELECTION = 'block-input-selection',
  BLOCK_TABLE = 'table',
  BLOCK_NINE_FIELD_MATRIX = 'block-nine-field-matrix',
  BLOCK_LISTING = 'block-listing',
  BLOCK_CLUSTER = 'block-cluster'
}
