export function camelToSnakeCase(str: string): string {
  const firstChar: string = str.slice(0, 1).toUpperCase();
  return (
    firstChar +
    str
      .slice(1)
      .replace(/[A-Z]/g, (letter: string) => `_${letter}`)
      .toUpperCase()
  );
}
